import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import CustomDatePicker from '../Components/FormComponents/CustomDatePicker';
import AsyncSelect from 'react-select/async';
import { searchClient } from '../requests/client.request';
import { ISearchTicketRequest, searchTickets } from '../requests/ticket.request';
import { TicketSearchResponseType } from '../schemas/ticket.schema';
import { DropdownItem, MonitoringList } from '../Types';
import TicketSearchTable from '../Components/TicketComponents/TicketsSearchTable';
import TicketPaginatedControls from '../Components/TicketComponents/TicketPaginatedControls';
import toast from 'react-hot-toast';
import { customAxiosFetch } from '../helpers/customFetch';
import { getMonitoringList } from '../requests/monitoring.request';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setRefreshTickets } from '../ReduxSlices/ticketsSlice';

const settledDropdown: DropdownItem<boolean>[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const autoPayNYCDropdown: DropdownItem<number>[] = [
  { value: 0, label: 'Unassigned' },
  { value: 1, label: 'True' },
  { value: 2, label: 'False' }
];

function TicketsPage() {
  const [filteredTickets, setFilteredTickets] = useState<TicketSearchResponseType>();
  const [selectedSummonNumber, setSelectedSummonNumber] = useState('');
  const [selectedClient, setSelectedClient] = useState<DropdownItem<string>>();
  const [insertedStartDate, setInsertedStartDate] = useState('');
  const [insertedEndDate, setInsertedEndDate] = useState('');
  const [selectedSettledValue, setSelectedSettledValue] = useState<DropdownItem<boolean>>();
  const [selectedPlateNumber, setSelectedPlateNumber] = useState<DropdownItem<string>>();
  const [plateNumberDropdown, setPlateNumberDropdown] = useState<DropdownItem<string>[]>();
  const [plateNumberTextField, setPlateNumberTextField] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const refreshTickets = useAppSelector((state) => state.tickets.refreshTickets);
  const dispatch = useAppDispatch();
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedAutopayNYCValues, setSelectedAutopayNYCValues] =
    useState<DropdownItem<number>[]>();
  const [isDlinquent, setIsDlinquent] = useState<boolean>(false);
  const [monitoringList, setMonitoringList] = useState<DropdownItem<string>[]>();
  const [selectedMonitoringList, setSelectedMonitoringList] = useState<DropdownItem<string>>();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (refreshTickets) {
      const fetchTickets = async () => {
        await handleSearchTickets();
      };
      fetchTickets();
      dispatch(setRefreshTickets(false));
    }
  }, [refreshTickets]);

  useEffect(() => {
    const getMonitoringListAsync = async () => {
      let monitoringList: MonitoringList[] = await getMonitoringList();
      setMonitoringList(
        monitoringList?.map((ml) => {
          return {
            value: ml.id,
            label: ml.name === 'unassigned' ? 'Not Monitoring' : `${ml.name}`
          };
        })
      );
    };
    getMonitoringListAsync();
  }, []);

  useEffect(() => {
    (async () => {
      await handleSearchTickets();
    })();
  }, [pageNumber]);

  const handleInsertedStartDateChange = (event) => {
    if (insertedEndDate !== '' && event.target.value > insertedEndDate) {
      toast.error('Start date should be less than the End date');
      return;
    }
    setInsertedStartDate(event.target.value);
  };

  const handleInsertedEndDateChange = (event) => {
    if (insertedStartDate !== '' && event.target.value < insertedStartDate) {
      toast.error('End date should be greater than the Start date');
      return;
    }
    setInsertedEndDate(event.target.value);
  };

  const handleSummonsNumberChange = (event) => {
    setSelectedSummonNumber(event.target.value);
  };

  const handleClientChange = (option) => {
    setSelectedClient(option);
    if (option && option.value !== null) getPlatesByClient(option.value);
    else {
      setPlateNumberDropdown([]);
      setSelectedPlateNumber(null);
    }
  };

  const handleSettledChange = (option) => {
    setSelectedSettledValue(option);
  };

  const handleAutopayNYCValues = (option) => {
    setSelectedAutopayNYCValues(option);
  };

  const handleIsDelinquent = (event) => {
    setIsDlinquent(event.target.checked);
  };

  const handleMonitoringDropdownChange = (option) => {
    setSelectedMonitoringList(option);
  };

  let timeoutId;
  const fetchClients = async (inputValue) => {
    clearTimeout(timeoutId);
    return new Promise<DropdownItem<string>[]>((resolve, reject) => {
      timeoutId = setTimeout(async () => {
        try {
          const { data } = await searchClient(`search-term=${inputValue}`);
          const options = data?.map((client) => ({
            value: client.id,
            label: client.name
          }));
          resolve(options);
        } catch (error) {
          reject(error);
        }
      }, 1000); // Simulating 1000ms delay
    });
  };

  async function handleFormSumbit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await handleSearchTickets();
      toast.success('Tickets fetched successfully.');
    } catch (error) {
      toast.error('Failed to fetch tickets.');
    }
    setIsLoading(false);
  }

  async function handleSearchTickets(downloadAsCsv = false) {
    const ticketSearchRequest: ISearchTicketRequest = {
      summonsNumber: selectedSummonNumber,
      clientId: selectedClient?.value,
      insertedStartDate: insertedStartDate,
      insertedEndDate: insertedEndDate,
      settled: selectedSettledValue?.value,
      plateId: selectedPlateNumber?.value,
      plateNumber: plateNumberTextField,
      monitoringListId: selectedMonitoringList?.value,
      isDelinquent: isDlinquent,
      autoPayToNYCOptions: selectedAutopayNYCValues?.map((item) => item.value),
      pageNumber: pageNumber
    };
    if (!downloadAsCsv) {
      try {
        const { data } = await searchTickets(ticketSearchRequest);
        setFilteredTickets(data);
      } catch (error) {
        await handleSearchTickets();
      }
    } else {
      await searchTickets(ticketSearchRequest, true);
    }
  }

  async function handleTicketsDownload(event) {
    event.preventDefault();
    setIsDownloading(true);
    try {
      await handleSearchTickets(true);
      toast.success('Tickets downloaded successfully.');
    } catch (error) {
      toast.error('Failed to download tickets.');
    }
    setIsDownloading(false);
  }

  const getPlatesByClient = (clientId) => {
    customAxiosFetch<
      [
        {
          id: string;
          text: string;
        }
      ]
    >({
      endpoint: `/plate/client/${clientId}`,
      callback: (result) => {
        const plateNumberDropdown: DropdownItem<string>[] = result.map((item) => {
          return {
            value: item.id,
            label: item.text
          };
        });
        setPlateNumberDropdown(plateNumberDropdown);
      }
    });
  };

  const handlePlateNumberDropdownChange = (option) => {
    setSelectedPlateNumber(option);
  };

  const handlePlateNumerTextFieldChange = (event) => {
    setPlateNumberTextField(event.target.value);
  };

  const handlePageNumberUpdate = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  return (
    <div className="mt-5">
      <Form onSubmit={handleFormSumbit}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Summons Number</Form.Label>
            <Form.Control
              type="text"
              value={selectedSummonNumber}
              onChange={handleSummonsNumberChange}
              placeholder="Summons Number"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Client</Form.Label>
            <AsyncSelect
              isClearable={true}
              cacheOptions={true}
              loadOptions={fetchClients}
              placeholder="Type client name"
              onChange={handleClientChange}
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Imported Start Date</Form.Label>
            <CustomDatePicker onChange={handleInsertedStartDateChange} value={insertedStartDate} />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Imported End Date</Form.Label>
            <CustomDatePicker onChange={handleInsertedEndDateChange} value={insertedEndDate} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Settled</Form.Label>
            <Select
              isClearable={true}
              options={settledDropdown}
              value={selectedSettledValue}
              onChange={handleSettledChange}
              placeholder="Settled"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Plate Number</Form.Label>
            <Select
              isClearable={true}
              options={plateNumberDropdown}
              value={selectedPlateNumber}
              onChange={handlePlateNumberDropdownChange}
              placeholder="Search plate number..."
              isDisabled={plateNumberTextField.length > 0}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>NYC Auto Pay</Form.Label>
            <Select
              isClearable={true}
              options={autoPayNYCDropdown}
              value={selectedAutopayNYCValues}
              onChange={handleAutopayNYCValues}
              placeholder="Auto pay NYC"
              isMulti={true}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Monitoring</Form.Label>
            <Select
              isClearable={true}
              options={monitoringList}
              value={selectedMonitoringList}
              onChange={handleMonitoringDropdownChange}
              placeholder="Monitoring list"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Plate Number</Form.Label>
            <Form.Control
              type="text"
              value={plateNumberTextField}
              onChange={handlePlateNumerTextFieldChange}
              placeholder="Plate number"
              disabled={selectedPlateNumber && selectedPlateNumber?.value !== null}
            />
          </Form.Group>
          <Form.Group as={Col} className="mt-2">
            <Form.Label>Delinquent</Form.Label>
            <Form.Check
              type="checkbox"
              id="custom-checkbox"
              className=""
              checked={isDlinquent}
              onChange={handleIsDelinquent}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} className="text-end">
            <Button disabled={isLoading} className="mx-2" type="submit">
              <Spinner size="sm" hidden={!isLoading} animation="border" className="mx-1" />
              {isLoading ? 'Searching...' : 'Search'}
            </Button>
            <Button
              disabled={isDownloading}
              className=""
              type="button"
              onClick={handleTicketsDownload}>
              <Spinner size="sm" hidden={!isDownloading} animation="border" className="mx-1" />
              {isDownloading ? 'Downloading...' : 'Download'}
            </Button>
          </Form.Group>
        </Row>
      </Form>
      <TicketSearchTable tickets={filteredTickets?.tickets} />
      <TicketPaginatedControls
        currentPage={filteredTickets?.currentPage}
        pages={filteredTickets?.pages}
        onPageUpdate={handlePageNumberUpdate}
      />
    </div>
  );
}
export default withAuthenticationRequired(TicketsPage);
