import React, { useState } from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { Send } from 'react-bootstrap-icons';
import toast from 'react-hot-toast';
import { sendClientDelinquentTicketsSummaryReport } from '../../requests/client.request';

export default function DelinquentTicketsReportEmailDispatcher() {
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const res = await sendClientDelinquentTicketsSummaryReport();
      toast.success(res.data);
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
    }
  }
  return (
    <Form onSubmit={handleSubmit} style={{ marginBottom: 15 }}>
      <Stack direction="horizontal" className="justify-content-between" gap={5}>
        <Form.Text style={{ whiteSpace: 'pre-wrap' }}>
          Send the delinquent tickets summary report to all active clients
        </Form.Text>
        <Button disabled={isLoading} type="submit">
          {isLoading ? <Spinner size="sm" animation="border" /> : <Send />}
        </Button>
      </Stack>
    </Form>
  );
}
