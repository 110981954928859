import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useMonitoringListQuery } from '../../queries/monitoring.query';
import { Client } from '../../Types';
import { customAxiosFetch } from '../../helpers/customFetch';
import { updateClientInRedux } from '../../ReduxSlices/clientsSlice';
import { fetchTicketsByPlates } from '../../Functions';
import ClientForm from '../ClientForm';

export const EditClient = () => {
  const selectedClientId = useAppSelector((state) => state.clients.selectedClientId);
  const client = useAppSelector((state) =>
    state.clients.allClientsList.find((c) => c.id === selectedClientId)
  );
  const [formData, setFormData] = useState(client);
  const dispatch = useAppDispatch();
  const { data } = useMonitoringListQuery({ enabled: true });
  const { unassignedMonitoringItem } = data ?? {};
  const handleDataChange = (key: keyof Client, value: unknown) =>
    setFormData({ ...formData, [key]: value });

  const updateClientInDatabase = (modifiedClient: Client) => {
    customAxiosFetch({
      endpoint: '/Client',
      callback: (r: Client) => dispatch(updateClientInRedux({ ...client, ...r })),
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          ...modifiedClient,
          monitoringListId: formData.monitoringListId
            ? formData.monitoringListId
            : unassignedMonitoringItem.id
        })
      }
    });
  };

  const submitClientForm = (e) => {
    e && e.preventDefault();
    const shouldSearchForTickets =
      client.monitoringListId !== formData.monitoringListId &&
      client.monitoringListId === unassignedMonitoringItem.id &&
      !!client.plates[0];
    updateClientInDatabase(formData);

    shouldSearchForTickets &&
      fetchTicketsByPlates(
        client.plates
          .filter((plate) => plate.archivedAt === null)
          .map((plate) => plate.plateNumber),
        { clientId: client.id }
      );
  };

  return (
    formData && (
      <div className="mb-4">
        <ClientForm
          formData={formData}
          handleDataChange={handleDataChange}
          handleSubmit={submitClientForm}
          clearForm={() => {}}
          unassignedMonitoringListId={unassignedMonitoringItem?.id}
          showCancelButton={false}
        />
      </div>
    )
  );
};
