import React from 'react';
import { EditClient } from '../Components/Clients/EditClient';
import { ClientSecondaryEmails } from '../Components/Clients/ClientSecondaryEmails';

export const ClientDetails = () => {
  return (
    <>
      <EditClient />
      <ClientSecondaryEmails />
    </>
  );
};
