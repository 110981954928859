import { Client, WEFResponse } from '../Types';
import api from '../configs/api.config';
import {
  ClientSearchDropdownkResponseType,
  ClientTicketSummaryReportResponseType
} from '../schemas/client.schema';

export interface IDownloadEmailsArgs {
  options: {
    params: {
      'monitoring-list-id': string;
    };
  };
}

export const downloadEmails = async (args: IDownloadEmailsArgs): Promise<Blob> => {
  const { options } = args;
  const { data } = await api.get('/Client/DownloadEmails', {
    ...options,
    responseType: 'blob'
  });
  return data;
};

export const downloadIgnoredTickets = async (): Promise<Blob> => {
  const { data } = await api.get('/Ticket/download/ignored-tickets', {
    responseType: 'blob'
  });
  return data;
};

interface ILoadFreshBooksClientsResponse {
  data: string | null;
  success: boolean;
  errorMessage: string | null;
}
export const importFreshBooksClients = async () => {
  const { data } = await api.get<ILoadFreshBooksClientsResponse>('/Client/ImportFreshBooksClients');
  return data;
};

export interface IClientRequest {
  params?: {
    page?: number;
    'search-term'?: string;
  };
}

interface IClientResponse {
  clients: Client[];
  pages: number;
  currentPage: number;
}

export const getClients = async (args?: IClientRequest) => {
  const { data } = await api.get<IClientResponse>('/Client', { params: args?.params });
  return data;
};

export interface ICreateClientRequest {
  name: string;
  freshbooksId: number;
  cardPointeProfileId: string;
  monitoringListId: string;
}
export const createClient = async (payload: ICreateClientRequest) => {
  const { data } = await api.post<Client>('/Client', payload);
  return data;
};

export interface IDeleteClientRequest {
  id: string;
  params: {
    'delete-from-freshbooks': boolean;
  };
}
export const deleteClient = async (args: IDeleteClientRequest) => {
  const { data } = await api.delete<string>(
    `/Client/${args.id}`,
    args.params['delete-from-freshbooks'] && { params: args.params }
  );
  return data;
};

export const searchClient = async (args: string) => {
  const { data } = await api.get<ClientSearchDropdownkResponseType>(`/Client/search?${args}`);
  return data;
};

export const sendClientTicketSummaryReport = async () => {
  const { data } = await api.post<ClientTicketSummaryReportResponseType>('/Client/send-reports');
  return data;
};

export const downloadEligibleForTowingClients = async (): Promise<Blob> => {
  const { data } = await api.get('/Client/download/eligible-for-towing', {
    responseType: 'blob'
  });
  return data;
};

export const sendClientDelinquentTicketsSummaryReport = async () => {
  const { data } = await api.post<WEFResponse<string>>('/Ticket/compose-delinquent-tickets-email');
  return data;
};
