import { setAllClientsList, setPagesInfo, updateClientInRedux } from './ReduxSlices/clientsSlice';
import { setAllMonitoringLists } from './ReduxSlices/monitoringListsSlice';
import { setResults } from './ReduxSlices/resultsSlice';
import { setFoundTickets } from './ReduxSlices/ticketsSlice';
import { store } from './Store';
import { Client, MonitoringList, OpenDataTicket, Plate, WEFResponse } from './Types';
import { customAxiosFetch, customFetch } from './helpers/customFetch';

export const fetchClients = (pageNumber: number = 1, searchTerm: string = null) => {
  customFetch({
    endpoint: `/Client?&page=${pageNumber}${searchTerm ? '&search-term=' + searchTerm : ''}`,
    callback: (result: { clients: Client[]; pages: number; currentPage: number }) => {
      store.dispatch(setAllClientsList(result.clients));
      store.dispatch(setPagesInfo(result));
    }
  });
};

export const fetchAllMonitoringLists = () => {
  customFetch({
    endpoint: '/MonitoringList',
    callback: (result: MonitoringList[]) => {
      store.dispatch(setAllMonitoringLists(result));
    }
  });
};

export const fetchClientTickets = (clientId: string) => {
  customFetch({
    endpoint: '/Ticket/GetClientTickets/' + clientId,
    processDescription: 'Fetching Tickets, please be patient.',
    callback: (result) => {
      const reduxState = store.getState();
      const updatedClient = {
        ...reduxState.clients.allClientsList.find((client) => client.id === clientId),
        tickets: result
      };
      store.dispatch(updateClientInRedux(updatedClient));
    }
  });
};

export const fetchTicketsByPlates = async (
  plateNumbers: string[],
  options: {
    callback?: () => void;
    includeKnown?: boolean;
    clientId?: string;
    state?: string;
  },
  isImport: boolean = false
) => {
  const { callback, includeKnown = true, clientId, state } = options;
  const queryUrl = new URLSearchParams();
  queryUrl.append('includeKnown', includeKnown.toString());
  if (clientId) {
    queryUrl.append('clientId', clientId);
  }
  if (state) {
    queryUrl.append('state', state);
  }
  const endpoint = `/Ticket/DiscoverTickets/${plateNumbers.toString()}?${queryUrl.toString()}`;
  await customFetch<{ tickets: OpenDataTicket[] }>({
    endpoint,
    callback: (r) => {
      store.dispatch(
        !Object.keys(r.tickets)[0]
          ? setResults({
              process: 'Searching for tickets by plate',
              results: 'No Tickets Found!'
            })
          : setFoundTickets({ OpenDataTickets: r.tickets, isImport: isImport })
      );
      callback?.();
    }
  });
};

export const printTickets = (endpoint) => {
  customFetch({
    endpoint: endpoint,
    skipParseBody: true,
    callback: (res: any) => {
      if (res.status === 204) {
        store.dispatch(
          setResults({
            process: 'Printing Tickets',
            results: 'No Tickets To Download!'
          })
        );
      } else {
        res.blob().then((r: Blob) => {
          let file = window.URL.createObjectURL(r);
          window.open(file, '_blank');
        });
      }
    }
  });
};

export const downloadAsCsv = (data: string, fileName) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${fileName}.csv`;
  hiddenElement.click();
};

export const updatePlateInDataBase = (
  editedPlate: Plate,
  callback: (updatedPlate: Plate) => void
) => {
  const putConfig = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(editedPlate)
  };
  customAxiosFetch({
    endpoint: '/Plate',
    callback: (modifiedPlate: WEFResponse<Plate>) => callback(modifiedPlate.data),
    config: putConfig
  });
};

export function localLogOut() {
  store.dispatch({ type: 'RESET' });
  localStorage.clear();
}

export const getClient = async (
  clientId: string,
  callback: (client: {
    client: Client;
    delinquent?: string;
    succesfullChargeAttempts: number;
    failedChargeAttempts: number;
  }) => void
) => {
  customAxiosFetch<{
    client: Client;
    delinquent?: string;
    succesfullChargeAttempts: number;
    failedChargeAttempts: number;
  }>({
    endpoint: '/Client/' + clientId,
    callback: (result) => callback(result)
  });
};
