import api from '../configs/api.config';
import { downloadBlob } from '../helpers/utils';
import { PrintTicketsRequestType, TicketSearchApiResponseType } from '../schemas/ticket.schema';

export const printTickets = async (summons: PrintTicketsRequestType) => {
  const response = await api.post('/printTickets', summons, {
    responseType: 'blob'
  });
  return response.data;
};

export const uploadBulkIgnoredTickets = async (formData: FormData) => {
  const response = await api.post('/ticket/upload/bulk-ignored-tickets', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export interface ISearchTicketRequest {
  summonsNumber?: string;
  clientId?: string;
  insertedStartDate?: string;
  insertedEndDate?: string;
  settled?: boolean;
  plateId?: string;
  plateNumber?: string;
  monitoringListId?: string;
  isDelinquent?: boolean;
  autoPayToNYCOptions?: number[];
  pageNumber: number;
}

export const searchTickets = async (payload: ISearchTicketRequest, downloadAsCsv = false) => {
  if (!downloadAsCsv) {
    const { data } = await api.post<TicketSearchApiResponseType>('/Ticket/get-tickets', payload);
    return data;
  } else {
    const response = await api.post('/Ticket/get-tickets?downloadAsCsv=true', payload, {
      responseType: 'blob'
    });
    // Extract the filename from Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    const match = contentDisposition?.match(/filename="(.+)"/);
    const fileName = match ? match[1] : 'Tickets WEF.csv';

    downloadBlob(response?.data, fileName);
  }
};

export const getClientDelinquentTickets = async (clientId: string) => {
  const { data } = await api.get<{ totalDelinquentTickets: number }>(
    `/Ticket/get-delinquent-tickets-count/${clientId}`,
    {
      timeout: 0
    }
  );
  return data?.totalDelinquentTickets;
};
