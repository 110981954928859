import React from 'react';
import { Pagination } from 'react-bootstrap';

interface IProps {
  currentPage?: number;
  pages?: number;
  onPageUpdate: (pageNumer: number) => void;
}
export default function TicketPaginatedControls({ currentPage, pages, onPageUpdate }: IProps) {
  return (
    <Pagination className="justify-content-center mt-3">
      <Pagination.First
        disabled={!currentPage || currentPage === 1}
        onClick={() => onPageUpdate(1)}
      />
      <Pagination.Prev
        disabled={!currentPage || currentPage === 1}
        onClick={() => onPageUpdate(currentPage - 1)}
      />
      <Pagination.Next
        disabled={currentPage === pages}
        onClick={() => {
          onPageUpdate(currentPage + 1);
        }}
      />
      <Pagination.Last disabled={currentPage === pages} onClick={() => onPageUpdate(pages)} />
    </Pagination>
  );
}
