import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { TicketSearchType } from '../../schemas/ticket.schema';
import { useNavigate } from '@tanstack/react-location';
import { customAxiosFetch } from '../../helpers/customFetch';
import { WEFResponse } from '../../Types';
import toast from 'react-hot-toast';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import { useAppDispatch } from '../../hooks';
import { setRefreshTickets } from '../../ReduxSlices/ticketsSlice';

interface IProps {
  tickets: TicketSearchType[];
}
export default function TicketSearchTable({ tickets }: IProps) {
  const navigate = useNavigate();
  const [ticketId, setTicketId] = useState<string>(null);
  const dispatch = useAppDispatch();

  function handleClientClick(clientId) {
    navigate({ to: `/clients/${clientId}` });
  }
  const handleDeleteTicketConfirmDialog = (ticketId: string) => {
    setTicketId(ticketId);
  };

  const handleDeleteTicket = async () => {
    await customAxiosFetch<WEFResponse<string>>({
      endpoint: '/Ticket/' + ticketId,
      config: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      },
      processDescription: 'Deleting Ticket',
      callback: async (r: any) => {
        setTicketId(null);
        dispatch(setRefreshTickets(true));
      },
      errorCallback: async (r: any) => {
        if (r.status === 403) {
          toast.error('You do not have permission to delete tickets');
        }
      }
    });
  };
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>SummonsNumber</th>
            <th>IsCameraTicket</th>
            <th>SummonsImage</th>
            <th>PrintedAt</th>
            <th>PaidToNycAt</th>
            <th>Settled</th>
            <th>InvoiceId</th>
            <th>EstimateId</th>
            <th>IgnoreTicket</th>
            <th>LastSyncedWithNYC</th>
            <th>ImportedBy</th>
            <th>ImportedDate</th>
            <th>UpdatedAt</th>
            <th>PlateNumber</th>
            <th>Client</th>
            <th>MonitoringList</th>
            <th>AutopayToNYC</th>
            <th>Delinquent</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tickets &&
            tickets.length > 0 &&
            tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td>{ticket.summonsNumber}</td>
                <td>{ticket.isCameraTicket}</td>
                <td>
                  <a href={ticket.downloadURL} target="blank">
                    View
                  </a>
                </td>
                <td>{ticket.printedAt}</td>
                <td>{ticket.paidToNycAt}</td>
                <td>{ticket.settled}</td>
                <td>{ticket.invoiceId}</td>
                <td>{ticket.estimateId}</td>
                <td>{ticket.ignoreTicket}</td>
                <td>{ticket.lastSyncedWithNYC}</td>
                <td>{ticket.insertedBy}</td>
                <td>{ticket.insertedAt}</td>
                <td>{ticket.updatedAt}</td>
                <td>{ticket.plateNumber}</td>
                <td
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => handleClientClick(ticket.clientId)}>
                  {ticket.clientName}
                </td>
                <td>{ticket.monitoringListName}</td>
                <td>{ticket.autoPayToNYC}</td>
                <td>{ticket.isDelinquent}</td>
                <td
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => handleDeleteTicketConfirmDialog(ticket.id)}>
                  Delete
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {ticketId && (
        <ConfirmDialog
          size="sm"
          isOpen={ticketId !== null}
          label={<>Delete ticket</>}
          body={<>Are you sure to delete this ticket?</>}
          confirmText="Delete"
          confirmAction={handleDeleteTicket}
          handleClose={() => setTicketId(null)}
        />
      )}
    </>
  );
}
