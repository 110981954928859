import React from 'react';
import { useNavigate } from '@tanstack/react-location';
import { setResults } from '../../ReduxSlices/resultsSlice';

import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { customAxiosFetch } from '../../helpers/customFetch';

function GenerateInvoicesButton() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleGenerateInvoices = () => {
    const callback = (result: {
      foughtTicketInvoices: [];
      linesFailedToProcess: [];
      unfoughtTicketInvoices: [];
    }) => {
      dispatch(
        setResults({
          process: 'Generating Invoices',
          results: {
            numberOfCreatedInvoices: result.foughtTicketInvoices.length,
            failedLines: result.linesFailedToProcess.length
          }
        })
      );
      navigate({ to: '/invoicing' });
      // navigate('/invoicing', { state: result });
    };
    customAxiosFetch<{
      foughtTicketInvoices: [];
      linesFailedToProcess: [];
      unfoughtTicketInvoices: [];
    }>({
      endpoint: '/Invoice/GenerateInvoices',
      config: { method: 'POST' },
      callback,
      processDescription: 'Generating Invoices'
    });
  };

  return <GlobalButton onClick={handleGenerateInvoices} buttonText={'Generate Invoices'} />;
}

export default GenerateInvoicesButton;
