import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { customAxiosFetch } from '../../helpers/customFetch';
import { Client, WEFResponse } from '../../Types';
import { getClient } from '../../Functions';
import { updateClientInRedux } from '../../ReduxSlices/clientsSlice';
import toast from 'react-hot-toast';

export const ClientSecondaryEmails = () => {
  const selectedClientId = useAppSelector((state) => state.clients.selectedClientId);
  const client = useAppSelector((state) =>
    state.clients.allClientsList.find((c) => c.id === selectedClientId)
  );
  const dispatch = useAppDispatch();
  const makeSecondaryEmailPrimary = (secondaryEmailId: string) => {
    customAxiosFetch<WEFResponse<string>>({
      endpoint: `/client/make-secondary-email-primary/${secondaryEmailId}`,
      config: { method: 'PUT' },
      callback: async (result) => {
        if (!result.success) return;
        toast.success(result.data);

        //call the client GET endpoint and update the record in redux
        const callback = (result: {
          client: Client;
          delinquent?: string;
          succesfullChargeAttempts: number;
          failedChargeAttempts: number;
        }) => {
          if (!result) return;
          const formattedResult = { ...result, ...result.client };
          delete formattedResult['client'];
          dispatch(updateClientInRedux(formattedResult));
        };
        await getClient(selectedClientId, callback);
      }
    });
  };

  return (
    <Stack className="mt-4 mb-4">
      <h2>Secondary Emails</h2>
      <Stack direction="vertical">
        {client.contactSecondaryEmails?.length > 0
          ? client.contactSecondaryEmails.map((item) => {
              return (
                <Stack direction="horizontal" key={item.id}>
                  <span className="col-md-2">{item.email}</span>
                  <Button variant="link" onClick={() => makeSecondaryEmailPrimary(item.id)}>
                    Set as Primary email
                  </Button>
                </Stack>
              );
            })
          : 'No secondary emails'}
      </Stack>
    </Stack>
  );
};
