import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

interface Props {
  value: any;
  name?: string;
  onChange: (_: any) => void;
  minDate?: string;
  maxDate?: string;
}
function CustomDatePicker({ value, name, onChange, minDate = null, maxDate = null }: Props) {
  return (
    <FormControl
      type="date"
      name={name}
      value={value}
      min={minDate}
      max={maxDate}
      onChange={onChange}
    />
  );
}

export default CustomDatePicker;
