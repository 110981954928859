import React from 'react';
import DownloadClientEmails from '../Components/DownloadClientEmails';
import DownloadClientPlates from '../Components/DownloadClientPlates';
import DownloadTicketBySummonsNumber from '../Components/DownloadTicketBySummonsNumber';
import FoundTicketsListModal from '../Components/FoundTicketsListModal';
import TicketSearchByPlate from '../Components/TicketSearchByPlate';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import DownloadIgnoredTickets from '../Components/DownloadIgnoredTickets';
import AddBulkIgnoreTickets from '../Components/AddBulkIgnoreTickets';
import TicketSummaryReportEmailDispatcher from '../Components/TicketSummaryReportEmailDispatcher';
import DownloadEligibleForTowingReport from '../Components/Reports/DownloadEligibleForTowingReport';
import DelinquentTicketsReportEmailDispatcher from '../Components/Reports/DelinquentTicketsReportEmailDispatcher';

function ReportsPage() {
  return (
    <div className="mt-5">
      <TicketSearchByPlate />
      <hr />
      <FoundTicketsListModal />
      <DownloadTicketBySummonsNumber />
      <hr />
      <DownloadClientEmails />
      <hr />
      <DownloadClientPlates />
      <hr />
      <DownloadIgnoredTickets />
      <hr />
      <TicketSummaryReportEmailDispatcher />
      <hr />
      <AddBulkIgnoreTickets />
      <hr />
      <DownloadEligibleForTowingReport />
      <hr />
      <DelinquentTicketsReportEmailDispatcher />
    </div>
  );
}

export default withAuthenticationRequired(ReportsPage);
