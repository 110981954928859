import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import JobsList from '../Components/Jobs/JobsList';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { DropdownItem, PaginatedJobs } from '../Types';
import { getJobs } from '../requests/jobs.request';
import { JobsPaginationControls } from '../Components/Jobs/JobsPaginationControls';

const jobTypes: DropdownItem<number>[] = [
  { value: 0, label: 'StageTickets' },
  { value: 1, label: 'GenerateInvoices' },
  { value: 3, label: 'ZeroOutInvoices' },
  { value: 4, label: 'StageInvoices' },
  { value: 5, label: 'ChargeAll' },
  { value: 6, label: 'StageTicketsFromDatabase' },
  { value: 7, label: 'ChargeOnlyDismissedInvoices' },
  { value: 8, label: 'GetUnPaidTickets' },
  { value: 9, label: 'DelinquentTicketsEmailReport' }
];

const JobsPage = () => {
  const [selectedJobType, setSelectedJobType] = useState<DropdownItem<number>>();
  const [paginatedJobs, setPaginatedJobs] = useState<PaginatedJobs>();
  const [isLoading, setIsLoading] = useState(true);
  let infoMsg = isLoading ? 'Loading...' : 'No jobs available';

  const handleJobTypeChange = (option) => {
    setSelectedJobType(option);
    getJobsFromAPI(option?.value);
  };

  useEffect(() => {
    getJobsFromAPI();
  }, []);

  const getJobsFromAPI = async (jobType = null, pageNumber = 1) => {
    const response = await getJobs(jobType, pageNumber);
    const { data } = response;
    setPaginatedJobs(data);
    setIsLoading(false);
  };

  return (
    <>
      <Row className="mt-5">
        <Col md={4}>
          <Form.Group>
            <Select
              isClearable={true}
              options={jobTypes}
              value={selectedJobType}
              onChange={handleJobTypeChange}
              placeholder="Select a job type"
            />
          </Form.Group>
        </Col>
      </Row>
      {!isLoading && paginatedJobs && paginatedJobs.jobs?.length > 0 ? (
        <>
          <JobsList jobs={paginatedJobs.jobs} />
          <JobsPaginationControls
            getJobsFromAPI={getJobsFromAPI}
            jobType={selectedJobType?.value}
            currentPage={paginatedJobs.currentPage}
            numberOfPages={paginatedJobs.numberOfPages}
          />
        </>
      ) : (
        <h2 className="text-center mt-2">{infoMsg}</h2>
      )}
    </>
  );
};

export default withAuthenticationRequired(JobsPage);
