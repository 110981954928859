import React, { useEffect, useState } from 'react';
import { useMatch, useSearch } from '@tanstack/react-location';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { customFetch } from '../helpers/customFetch';
import { Client, DeepClient, FreshBookImportClient } from '../Types';
import {
  synchronizeFreshBookClientInRedux,
  updateClientInRedux
} from '../ReduxSlices/clientsSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Stack, Tab, Tabs, Button } from 'react-bootstrap';
import ClientPlates from '../Containers/ClientPlates';
import ClientPayments from '../Containers/ClientPayments';
import ClientTickets from '../Containers/ClientTickets';
import FoundTicketsListModal from '../Components/FoundTicketsListModal';
import { setFoundTickets } from '../ReduxSlices/ticketsSlice';
import DeleteClient from '../Components/DeleteClient';
import ShowClientInFreshBooks from '../Components/Buttons/ShowClientInFreshBooks';
import ClientTags from '../Components/ClientTags';
import { setEditingPlate } from '../ReduxSlices/platesSlice';
import ClientInfo from '../Components/ClientInfo';
import { setChangeLogs } from '../ReduxSlices/changeLogsSlice';
import { clearTicketTabSlice, setTicketTab } from '../ReduxSlices/ticketsTabSlice';
import { ClientDetails } from '../Containers/ClientDetails';
import { getClient } from '../Functions';

const tabs = {
  plates: undefined,
  payments: undefined,
  tickets: undefined,
  clientDetails: undefined
};

type TabKeys = keyof typeof tabs;

function ClientDetailsPage() {
  const dispatch = useAppDispatch();
  const {
    params: { clientId }
  } = useMatch();

  const client = useAppSelector((state) =>
    state.clients.allClientsList.find((c) => c.id === clientId)
  );
  const foundTickets = useAppSelector((state) => state.tickets.foundTickets);

  const selectedClientId = useAppSelector((state) => state.clients.selectedClientId);
  const isClientValid = client?.id === selectedClientId;

  const { activeTab } = useSearch() as { activeTab: TabKeys };
  const isValidTab = activeTab in tabs;
  const [key, setKey] = useState<TabKeys>(isValidTab ? activeTab : 'plates');

  useEffect(() => {
    dispatch(setFoundTickets({ OpenDataTickets: [], isImport: false }));
    dispatch(setEditingPlate(null));
  }, []);

  useEffect(() => {
    const fetchClient = async () => {
      const callback = (result: {
        client: Client;
        delinquent?: string;
        succesfullChargeAttempts: number;
        failedChargeAttempts: number;
      }) => {
        if (!result) return;
        const formattedResult = { ...result, ...result.client };
        delete formattedResult['client'];
        dispatch(updateClientInRedux(formattedResult));
      };
      await getClient(clientId, callback);
    };
    fetchClient();
  }, []);

  const handleTabSelect = (selectedKey: TabKeys) => {
    dispatch(clearTicketTabSlice());
    setKey(selectedKey);
    window.history.pushState({}, undefined, `?activeTab=${selectedKey}`);
    return;
  };
  const handleShowDelinquentTicketsTab = () => {
    handleTabSelect('tickets');
    dispatch(setTicketTab('delinquent'));
  };

  const tabComponents = {
    plates: <ClientPlates client={client as DeepClient} />,
    payments: <ClientPayments client={client} />,
    tickets: <ClientTickets />,
    clientDetails: <ClientDetails />
  };

  const showClientChangeLogsModal = (id: string) => {
    dispatch(
      setChangeLogs({
        showChangeLogs: true,
        entityId: id,
        entityName: 'Client'
      })
    );
  };

  const importFromFreshBooks = () => {
    customFetch({
      endpoint: `/Client/import-freshBooks-client/${clientId}`,
      callback: (result: { data: FreshBookImportClient }) => {
        dispatch(synchronizeFreshBookClientInRedux(result.data));
      }
    });
  };
  const handleEditClick = () => {
    handleTabSelect('clientDetails');
  };

  return client !== undefined && isClientValid ? (
    <div className="my-5">
      <Stack direction="horizontal">
        <ClientInfo client={client} />
        <Stack direction="horizontal" gap={2}>
          <Button className="ms-auto" variant="outline-primary" onClick={handleEditClick}>
            Edit
          </Button>
          <Button
            className="ms-auto"
            variant="outline-secondary"
            onClick={() => showClientChangeLogsModal(client.id)}>
            History
          </Button>
        </Stack>
      </Stack>
      <ClientTags client={client} showDelinquentTicketTab={handleShowDelinquentTicketsTab} />

      <Tabs activeKey={key} onSelect={handleTabSelect} justify>
        <Tab eventKey="plates" title={<h2>Plates</h2>}></Tab>
        <Tab eventKey="payments" title={<h2>Payments</h2>}></Tab>
        <Tab eventKey="tickets" title={<h2>Tickets</h2>}></Tab>
        <Tab eventKey="clientDetails" title={<h2>Client Details</h2>}></Tab>
      </Tabs>
      {tabComponents[key]}
      {!!Object.keys(foundTickets.OpenDataTickets)[0] && (
        <FoundTicketsListModal clientId={client?.id} />
      )}
      <Stack direction="horizontal" className="ms-auto mt-1" gap={2}>
        <span className="ms-auto">
          <DeleteClient />
        </span>
        <ShowClientInFreshBooks freshBooksId={client.freshbooksId} />
        <Button variant="primary" onClick={importFromFreshBooks}>
          Import from FreshBooks
        </Button>
      </Stack>
    </div>
  ) : (
    <h3 className="my-5 text-center">Loading...</h3>
  );
}
export default withAuthenticationRequired(ClientDetailsPage);
